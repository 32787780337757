@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f7;
  font-style: normal;
  color: #1D1D1F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,h2,h3,h4,h5 {
  color: #1D1D1F;
}

.footer-bg {
  background-image: url(assets/footer-bg.jpg);
  background-size: cover;
  object-fit: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

/* Title Box Shadow */
.title {
  text-shadow: 
    2px 2px 5px rgba(0, 0, 0, 0.3),   /* First shadow - subtle */
    -2px -2px 5px rgba(0, 0, 0, 0.3),  /* Second shadow - subtle */
    0 0 10px rgba(0, 0, 0, 0.5);       /* Soft glow effect */
}

/* Subtitle Box Shadow */
.subtitle {
  text-shadow: 
    1px 1px 3px rgba(0, 0, 0, 0.3),   /* First shadow - subtle */
    -1px -1px 3px rgba(0, 0, 0, 0.3),  /* Second shadow - subtle */
    0 0 8px rgba(0, 0, 0, 0.4);        /* Soft glow effect */
}

.shadow-nav {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.04);
}


.logo-color-change {
  filter: invert(100%) sepia(530%) saturate(3632%) hue-rotate(23deg) brightness(100%) contrast(101%);
}

.hamburger-bg {
  background-image: url(assets/hamburger-bg.jpg);
  background-size: cover;
  object-fit: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media(max-width:800px) {
  .footer-bg {
    background-image: url(assets/bg-mobile-footer.jpg);
    background-size: cover;
    object-fit: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}

.underline {
  text-decoration: none!important;
}

h1 {
  word-break: break-word!important;
}